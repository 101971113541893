























































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniTable from "@/components/table/SygniTable.vue";
import SygniModal from "@/components/layout/SygniModal.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniDatePicker from "@/components/inputs/SygniDatePicker.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniSquareButton from "@/components/buttons/SygniSquareButton.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import {BSpinner} from "bootstrap-vue";
import SygniCircleButton from "@/components/buttons/SygniCircleStatusButton.vue";
import breakpoints from '@/plugins/breakpoints';
import { Dictionaries } from '@/modules/genprox/modules/fund/modules/portfolio/store/types';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {
    SygniCircleButton, SygniArrowButton, SygniRoundedButton, UserPresentation, ExpandCollapse, BSpinner, SygniSelect, SygniCheckbox, SygniDatePicker, SygniSquareButton, SygniRectButton, SygniModal },
})
export default class ReportingFormTable extends SygniTable<any> {
  @Prop({ default: [] }) reportList: any[]
  dictionaries!: Dictionaries;
  refresh: any = null;

  breakpoints = breakpoints;

  get computedTableFields() {
    const fields: any[] = [
      { key: 'name', sortable: false, class: 'text-left', label: 'Report Name'},
      { key: 'actions', sortable: false, class: 'actions', borderless: true, label: 'Actions'},
    ];

    if (this.hasAccountingProgram) {
      fields.splice(1, 0, { key: 'period', sortable: false, class: 'text-left period-col', label: 'Reporting Period'})
    }

    return fields
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get hasAccountingProgram() {
    return !!(this.activeUserData?.accountingProgram)
  }
}

